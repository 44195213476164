@import url('https://fonts.googleapis.com/css2?family=Telegraf:wght@700&family=Telegraf:wght@400&display=swap');

.banner-container {
  background-color: #CFA4F3;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  position: relative;

  .banner-text {
    flex: 1;
    text-align: left;
    padding: 20px;

    h1 {
      font-size: 96px;
      margin-bottom: 20px;
    }

    p {
      font-size: 36px;
    }
  }

  .banner-image {
    flex: 1;
    text-align: right;
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .app-logos {
    display: flex;
    align-items: center;
    padding-top: 20px;
    position: relative;

    .playstore-logo {
      margin-right: 10px; 
    }
  }
}

.section2,
.section3,
.section4 {
  width: 100%;
  min-height: 100vh;

  &.section2 {
    background-color: #f4eff7;
  }

  &.section3 {
    background-color: #CFA4F3;
  }

  &.section4 {
    background-color: #f4eff7;
    min-height: 50vh;
  }
}

@media (max-width: 768px) {
  .banner-container {
    align-items: flex-start;
    max-width: 95%;

    .banner-text {
      text-align: left;
      padding: 20px;

      h1 {
        font-size: 48px;
        margin-bottom: 10px;
      }

      p {
        font-size: 24px;
      }
    }

    .banner-image {
      text-align: center;
      margin-top: 20px;
      max-width: 80%;
    }

    .app-logos {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 10px;

      .playstore-logo {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 80%;
      }

      .appstore-logo {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 80%;
      }
    }
  }
}

@media (max-width: 425px) {
  .banner-container {
    flex-direction: column;
    align-items: flex-start;
    max-width: 90%;
  }
}
