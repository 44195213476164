@import url('https://fonts.googleapis.com/css2?family=Telegraf:wght@700&family=Telegraf:wght@400&display=swap');

.depositions-container {
    max-width: 100%;
    text-align: center;
    background-color: #CFA4F3;
    margin-bottom: 20px;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  
    h2 {
      font-size: 56px;
      margin-bottom: 20px;
      color: white;
    }
  
    .depostionC-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 20px;
  
      .deposition {
        width: 200px;
        height: 300px;
        background-color: #CFA4F3;
        border: 1px solid white;
        border-radius: 8px;
        padding: 20px;
        margin: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        h3 {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
    }
  }
  
  @media (max-width: 769px) {
    .depositions-container {
      padding: 10px;
      
      h2 {
        font-size: 36px;
      }
  
      .depostionC-container {
        flex-direction: column;
  
        .deposition {
          width: 70%;
        }
      }
    }
  }
  