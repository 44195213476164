@import url('https://fonts.googleapis.com/css2?family=Telegraf:wght@700&family=Telegraf:wght@400&display=swap');

.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    max-width: 1200px;
    min-height: 80vh;
    margin: 0 auto;
  
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  
    .video {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .youtube-video {
        width: 80%;
        height: 600px;
      }
    }
  
    .card {
      flex: 1;
      background-color: #CFA4F3;
      border: 1px solid #ddd;
      padding: 20px;
      border-radius: 20px;
      max-width: 360px;
      margin-top: 60px;
      height: 400px;
  
      h2 {
        font-size: 36px;
        margin-bottom: 10px;
      }
  
      h3 {
        font-size: 24px;
        margin-bottom: 10px;
        color: white;
      }
  
      p {
        font-size: 16px;
      }
    }
  }
  
  footer {
    margin-top: 20px;
  }
  