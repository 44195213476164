.quem-somos .overlay-text h1,
.nova-secao-imagem-direita h2,
.nova-secao-imagem-esquerda h2,
.titulo,
.card-titulo,
.card-conteudo,
.card-subtitulo,
.caixa1 p,
.caixa2 p,
.caixa3 p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.quem-somos .overlay-text h1 {
  font-weight: 400;
}

.nova-secao-imagem-direita h2,
.nova-secao-imagem-esquerda h2,
.titulo,
.card-titulo,
.card-subtitulo {
  font-weight: 600;
}

.quem-somos {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.background-image {
  width: 100%;
}

.overlay {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(100% - 20px);
  margin-right: 10%;
  margin-top: -35px;
}


.overlay-text {
  text-align: center;
  color: black;
  padding: 20px;
  max-width: 400px;
  position: relative;
  margin-right: auto;
  margin-left: 20vh;
 
}

.overlay-text p {
  font-size: 32px;
  line-height: 1.5;
}

.imagem_banner02 {
  margin: auto;
}

.nova-secao {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.nova-secao img {
  margin: auto;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.texto {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 20px;
  max-width: 45%;
  text-align: right;
  flex-direction: column;
}

.texto h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: left;
}

.texto p {
  font-size: 32px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: left;
}

.nova-secao-imagem-esquerda {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  flex-direction: row-reverse; 
}

.nova-secao-imagem-esquerda img {
  max-width: 50%; 
  height: auto;
}

.nova-secao-imagem-esquerda .texto {
  flex: 1;
  padding: 20px;
  max-width: 50%; 
  text-align: left;
  margin-right: 20px; 
}

.nova-secao-imagem-esquerda h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: left;
}

.nova-secao-imagem-esquerda p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: left;
}

.nova-secao-imagem-direita {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  flex-direction: row-reverse; 
}

.nova-secao-imagem-direita img {
  max-width: 50%; 
  height: auto;
}

.nova-secao-imagem-direita .texto {
  flex: 1;
  padding: 20px;
  max-width: 50%; 
  text-align: left;
  margin-right: 20px; 
}

.nova-secao-imagem-direita h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: left;
}

.nova-secao-imagem-direita p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: left;
}

.container {
  text-align: center;
}

.titulo {
  font-size: 36px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.cards {
  display: flex;
  justify-content: space-around;
  margin: auto;
 
}

.card {
  max-width: 100%;
  margin: 20px;
  background-color: #caa0ff;
  border: none;
  padding: 20px;
  text-align: center;
  border-radius: 12%;
  margin-bottom: 20px;
}

.card-titulo {

  font-size: 18px;
  margin-bottom: 10px;
}

.card-conteudo {
  align-items: center;
  font-size: 16px;
}

@media (max-width: 768px) {
  .quem-somos .background-image {
    height: 60vh;
  }

  .quem-somos .overlay {
    flex-direction: column-reverse;
  }

  .nova-secao {
    flex-direction: column;
    align-items: center;
  }

  .nova-secao img {
    width: 100%;
    max-width: 100%;
  }

  .cards {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-bottom: 20px;
  }

  .titulo {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .background-image,
  .overlay,
  .nova-secao,
  .overlay-text,
  .overlay-image {
    height: auto;
  }

  .overlay {
    flex-direction: column;
    padding: 20px;
    margin: auto;
  }

  .overlay-text {
    margin-left: 0;
    max-width: 100%;
    text-align: center;
    margin-top: -35px;
  }

  .overlay-image {
    max-width: 80%;
    height: auto;
  }

  .segunda-secao {
    margin-top: 20px;
  }

  .nova-secao {
    flex-direction: column;
    text-align: center;
  }

  .nova-secao img {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .texto {
    max-width: 100%;
    text-align: center;
  }

  .caixa1,
  .caixa2,
  .caixa3 {
    text-align: center;
  }

  .cards {
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .background-image {
    height: 50%;
  }

  .overlay-text {
    max-width: 400px;
    margin-left: 20vh;
    text-align: center;
    margin-top: 0;
  }

  .nova-secao {
    flex-direction: row;
    text-align: left;
  }

  .nova-secao img {
    width: auto;
    max-width: 100%;
    margin: auto;
  }

  .texto {
    max-width: 45%;
    text-align: right;
  }

  .caixa1,
  .caixa2,
  .caixa3 {
    text-align: left;
  }
}
