@import url('https://fonts.googleapis.com/css2?family=Telegraf:wght@700&family=Telegraf:wght@400&display=swap');

.first-div {
  display: flex;
  align-items: center;
  background-color: #CFA4F3;
  padding: 20px;
}

.text {
  flex: 1;
}

.image img {
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: 20px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text h1 {
  color: black;
  font-size: 56px;
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .first-div {
    flex-direction: column;
    padding: 10px;
  }

  .text h1 {
    font-size: 20px;
  }
}

@media (max-width: 425px) {
  .image img {
    margin-left: auto;
    margin-right: auto;
  }
}
