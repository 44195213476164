@import url('https://fonts.googleapis.com/css2?family=Telegraf:wght@700&family=Telegraf:wght@400&display=swap');

.contact-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-weight: bold;
  max-width: 100%;
  margin: 0 auto;

  .left-content {
    text-align: left;
    width: 100%;
    max-width: none;

    h2 {
      font-size: 36px;
      margin-bottom: 10px;
      color: #9414ED;
    }

    .contact-info {
      p {
        font-size: 28px;
        margin-right: 10px;
        color: #C572FF;
      }
    }

    .social-icons {
      display: flex;
      align-items: center;
      gap: 20px;

      p {
        color: #C572FF;
        font-size: 28px;
        margin: 0;
        line-height: 40px;
      }
    }

    .email-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
    }
  }

  .icone-preto {
    filter: grayscale(100%);
  }
}

@media (max-width: 769px) {
  .contact-container {
    max-width: 100%; 
  }

  .email-container {
    justify-content: center;
    margin-top: 0;
    flex-wrap: wrap;
  }

  .right-content {
    width: auto;
    margin-bottom: -275px;
    margin-right: -20px;
  }

  .right-content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}


@media (min-width: 769px) {
  .contact-container {
    flex-direction: row;
    max-width: none;
  }

  .left-content {
    width: auto;
  }

  .right-content {
    position: static;
    margin-top: 0; 
  }

  .right-content {
    width: auto;
    margin-bottom: -23px; 
    margin-right: -23px;
  }
}
